<template>
  <div>
    <div class="section-header">
      <div class="row" style="justify-content: right;">
        <v-col cols="2" sm="2" md="2" class="pl-3" style="margin-right: 0">
          <v-select
            v-model="gameType"
            :items="items"
            item-value="id"
            item-text="name"
            label="Game Type #  "
            class="game-type"
            style="min-width: 100%"
            @input="selectGameType(gameType)"
            outlined
            >
          </v-select>
        </v-col>
        <v-col
          cols="2"
          sm="2"
          md="2"
          class="text-right pl-3"
          style="margin-right: 0px"
        >
          <v-select
            v-model="currencyType"
            :items="items2"
            item-value="id"
            item-text="name"
            label="Currency Type #  "
            class="currency-type"
            style="min-width: 100%"
            @input="selectCurrencyType(currencyType)"
            outlined
          >
          </v-select>
        </v-col>
        <v-col cols="2" sm="2" md="2" class="pl-3" style="margin-right: 0">
          <v-select v-if="user &&(user.role_id == '612a40a51a1fcead8871e833')"
            v-model="appId"
            :items="items3"
            item-value="id"
            item-text="name"
            label="App Name #  "
            class="app-name"
            style="min-width: 100%;"
            @input="selectAppName(appId)"
            outlined
            >
          </v-select>
        </v-col>
        <div
          class="col-md-2 col-sm-6"
        >
          <v-menu
            v-model="v_startDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="pl-5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('header.start')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="v_startDate = false">
            </v-date-picker>
          </v-menu>
        </div>
        <div
          class="col-md-2 col-sm-6"
        >
          <v-menu
            v-model="v_endDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('header.finish')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="v_endDate = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div
          class="col-md-1 col-sm-12"
        >
          <v-text-field
            v-model="v_search"
            append-icon="mdi-magnify"
            :label="$t('header.search')"
            single-line
            hide-details
            clearable
            class="pl-5"
          />
        </div>

        <div class="col-md-1 col-sm-12" style="width: 100%; text-align: center">
          <v-btn
            style="display: inline-block; margin-top: 13px"
            class="white--text btn-btn-sucess"
            color="primary"
            depressed
            @click="getData()"
          >
            {{ $t("header.search") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: [
    "darkmode",
    "status",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "time",
    "startTime",
    "endTime",
  ],
  data() {
    return {
      data: {
        descendingFormat: [false],
        sortByFormat: ["amount_win"],
        descending: false,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
        "_id.com",
        "_id.app_id",
        "amount",
        "amount_win"
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD"),
        // statusTime: false,
      },
      gameType:1,
      currencyType: 1,
      items: [
        { name: "Cock", id: 1 },
        { name: "Casino", id: 2 },
      ],
      items2: [
        { name: "Dollar", id: 1 },
        { name: "Riel", id: 2 },
      ],
      appId : 0,
      items3: [
        { name: "All", id: 0 },
        { name: "aa2288", id: 1 },
        { name: "wa855", id: 2 },
        { name: "2bwinner", id: 3 },
        { name: "cf9", id: 4 },
        { name: "cflive", id: 5 },
        { name: "aa18", id: 6 },
      ],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      v_startTime: "00:00",
      v_endTime: "23:59",
      v_search: "",
      // statusTime: false,
    };
  },
  watch: {
    time: function (newVal) {
      // Emit this information to the parents component
      this.startDate = newVal.startDate;
      this.endDate = newVal.endDate;
      this.v_startTime = newVal.startTime;
      this.v_endTime = newVal.endTime;
      // this.statusTime = newVal.statusTimeOrigin;
    },
  },

  mounted() {
    if (this.$cookies.get("game-type")) {
      this.gameType = parseInt(this.$cookies.get("game-type"));
    }
    if (this.$cookies.get("currency-type")) {
      this.currencyType = parseInt(this.$cookies.get("currency-type"));
    }
    if (this.$cookies.get("app_id")) {
      this.appId = parseInt(this.$cookies.get("app_id"));
    }
  },

  computed: {
    titleInfo: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return " Agent Statement";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return " Agent Statement";
        else if (
          this.user.role_name == "SENIOR-D" ||
          this.user.role_name == "SUB-SENIOR-D"
        )
          return " Agent Statement";
        else if (
          this.user.role_name == "MASTER-D" ||
          this.user.role_name == "SUB-MANAGER-D"
        )
          return " Agent Statement";
        else if (
          this.user.role_name == "AGENT-D" ||
          this.user.role_name == "SUB-AGENT-D"
        )
          return " Agent Statement";
        else return "Loading...";
      } else return "Loading...";
    },
    reportUrl: function () {
      if (this.user) {
        if (
          this.user.role_name == "DEVELOPER" ||
          this.user.role_name == "MANAGER" ||
          this.user.role_name == "SPECIAL-MANAGER"
        )
          return "agent";
        else if (
          this.user.role_name == "COMPANY" ||
          this.user.role_name == "SUB-COMPANY"
        )
          return "agent";
        else if (
          this.user.role_name == "SENIOR-D" ||
          this.user.role_name == "SUB-SENIOR-D"
        )
          return "agent";
        else if (
          this.user.role_name == "MASTER-D" ||
          this.user.role_name == "SUB-MASTER-D"
        )
          return "agent";
        else if (
          this.user.role_name == "AGENT-D" ||
          this.user.role_name == "SUB-AGENT-D"
        )
          return "agent";
        else return null;
      } else return null;
    },
  },
  methods: {
    selectCurrencyType(currencyType) {
      this.$cookies.set("currency-type", currencyType);
      this.currencyType = parseInt(this.$cookies.get("currency-type"));
      if (
        parseInt(this.$cookies.get("currency-type")) == 1 ||
        parseInt(this.$cookies.get("currency-type")) == 2 ||
        parseInt(this.$cookies.get("currency-type")) == 3
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("currency-type")
        );
      //this.$router.go();
    },
    selectGameType(gameType) {
      this.$cookies.set("game-type", gameType);
      this.gameType = parseInt(this.$cookies.get("game-type"));
      if (
        parseInt(this.$cookies.get("game-type")) == 1 ||
        parseInt(this.$cookies.get("game-type")) == 2 
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("game-type")
        );
      //this.$router.go();
    },
    selectAppName(appId) {
      this.$cookies.set("app_id", appId);
      this.appId = parseInt(this.$cookies.get("app_id"));
      if (
        parseInt(this.$cookies.get("app_id")) == 1 ||
        parseInt(this.$cookies.get("app_id")) == 2 ||
        parseInt(this.$cookies.get("app_id")) == 3 ||
        parseInt(this.$cookies.get("app_id")) == 4 ||
        parseInt(this.$cookies.get("app_id")) == 5 ||
        parseInt(this.$cookies.get("app_id")) == 6 
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("app_id")
        );
      //this.$router.go();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async getData() {
      this.startTime = this.v_startTime;
      this.endTime = this.v_endTime;
      let formatStartDate = "";
      let formatEndDate = "";
      formatStartDate = this.startDate + "T" + this.startTime + ":00";
      formatEndDate = this.endDate + "T" + this.endTime + ":59";
      this.data.startDate = formatStartDate;
      this.data.endDate = formatEndDate;
      this.data.search = this.v_search;
      // this.data.statusTime = this.statusTime ? true : false;
      let preData = {
        data: this.data,
        appId:this.appId,
        gameType:this.gameType,
        currencyType:this.currencyType,
        reportUrl: this.reportUrl,
      };
      this.fetchListStatement(preData).then(() => {
        let date = {
          startDate: formatStartDate,
          endDate: formatEndDate,
          appId:this.appId,
          gameType:this.gameType,
          currencyType:this.currencyType,
          // statusTime: this.statusTime,
        };
        this.$emit("child-checkbox", date);
      });
    },
    ...mapActions("$_typeGameSummaryAgent", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom {
  position: relative;
  // right: 250px;
  top: 10px;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.custom-menu {
  max-width: 11%;
}

.custom-input-time {
  border: 1.5px solid black;
  padding: 2px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
